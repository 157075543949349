import Icon from '@dx-ui/osc-icon';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { GetHotelHomePageQuery, GetHotelLayoutQuery } from '../../generated/types';
import { getDistance } from '../../helpers/property-utils';
import { useIsTailored } from '../../hooks/use-is-tailored-experience';
import Map from '../map/map';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

export type TLocationAndTransportationProps = TWithCustomTheme<{
  address: NonNullable<NonNullable<GetHotelLayoutQuery['hotelPageTemplate']>['hotel']>['address'];
  airports?: NonNullable<GetHotelHomePageQuery['hotel']>['airports'];
  brandCode: string;
  coordinate?: NonNullable<
    NonNullable<GetHotelHomePageQuery['hotel']>['localization']
  >['coordinate'];
  hotelName: string;
  ctyhocn: string;
  serviceAmenities?: NonNullable<GetHotelHomePageQuery['hotel']>['serviceAmenities'];
  hideBrandLogo?: boolean;
}>;

const LocationAndTransportation = ({
  address,
  airports,
  brandCode,
  coordinate,
  ctyhocn,
  hotelName,
  serviceAmenities,
  hideBrandLogo = false,
  wrapperClass,
}: TLocationAndTransportationProps) => {
  const { t } = useTranslation('hotel-location');
  const language = useRouter().locale || 'en';
  const isTailored = useIsTailored();

  const nearbyLocations = () => {
    const sortedServiceAmenities = serviceAmenities
      ?.filter((amenity) => amenity?.distance)
      .sort((a, b) => (a.distance && b.distance ? a.distance - b.distance : 0))
      .slice(0, 6);

    return sortedServiceAmenities?.map(({ name, distance, distanceFmt, distanceUnit }) => (
      <div
        className={cx('mb-2 flex justify-between border-b p-2', {
          'border-bg-alt': !isTailored,
          'border-primary': isTailored,
        })}
        key={name}
        data-testid="serviceAmenityElement"
      >
        <div
          className={cx('flex pr-5 text-base font-bold gap-2 items-center', {
            'font-medium': isTailored,
          })}
        >
          <Icon name="location" size="md" />
          <span>{name}</span>
        </div>
        {distance && distanceFmt && distanceUnit ? (
          <div className="whitespace-nowrap text-left">
            {getDistance({ t, distance, distanceUnit, language })}
          </div>
        ) : null}
      </div>
    ));
  };

  const airportShuttleInfo = (
    transportations: NonNullable<
      GetHotelHomePageQuery['hotel']
    >['airports'][number]['transportations']
  ) => {
    if (transportations.length > 0) {
      return transportations.map((transportation) => (
        <div className="px-4 pb-2" key={transportation?._id}>
          <div className="flex gap-1 py-2 text-sm font-semibold">
            <Icon name="check" size="sm" /> <span>{t('available')}</span>
            <Icon name="check" size="sm" />
            <span>
              {transportation?.charge && transportation.charge > 0 ? t('fee') : t('complimentary')}
            </span>
          </div>
          <p className="text-base">{transportation?.desc}</p>
        </div>
      ));
    }
    return (
      <div className="flex gap-1 px-4 py-2">
        <Icon name="close" size="sm" />
        <p className="text-sm font-semibold">{t('notAvailable')}</p>
      </div>
    );
  };

  const airportInfo = () => {
    const sortedAirports = airports
      ?.sort((a, b) => (a.distance && b.distance ? a.distance - b.distance : 0))
      .slice(0, 3);

    return sortedAirports?.map(
      ({
        name,
        code,
        distance,
        distanceFmt,
        defaultDistanceUnit: distanceUnit,
        transportations,
      }) => (
        <div className="flex flex-col" key={code} data-testid="airportInfoElement">
          <div
            className={cx('mb-2 flex flex-row justify-between border-b p-2', {
              'border-bg-alt': !isTailored,
              'border-primary': isTailored,
            })}
          >
            <div className="flex flex-row gap-1 pr-5 text-base font-bold">
              <Icon name="airport" />
              <span>{name}</span>
            </div>
            {distance && distanceFmt && distanceUnit ? (
              <div className="whitespace-nowrap text-left">
                {getDistance({ t, distance, distanceUnit, language })}
              </div>
            ) : null}
          </div>
          <p className="px-4 text-base">{t('airportShuttle')}</p>
          {airportShuttleInfo(transportations)}
        </div>
      )
    );
  };

  const sharedTabListButtonProps: Omit<React.ComponentProps<typeof TabListButton>, 'id'> = {
    activeClassName: cx('text-primary', {
      '!border-primary brand-es-refresh:!border-secondary': !isTailored,
      '!border-brand-alt': isTailored,
    }),
  };

  return (
    <div
      className={cx('bg-bg py-8 lg:py-10', wrapperClass)}
      data-testid="locationAndTransportationContainer"
    >
      <h2 className="heading-2xl lg:heading-3xl mb-8 text-center">
        {t('locationAndTransportation')}
      </h2>
      <div
        className={cx('container flex flex-row flex-wrap justify-between gap-10 md:flex-nowrap', {
          'font-primary': isTailored,
        })}
      >
        <Tabs className="order-2 w-full flex-col-reverse md:order-1 md:w-1/2">
          <TabList>
            <TabListButton {...sharedTabListButtonProps} id="nearby-locations">
              <span className="heading-base md:heading-2xl">{t('whatsNearby')}</span>
            </TabListButton>
            <div className="bg-bg-alt h-11 w-px" />
            <TabListButton {...sharedTabListButtonProps} id="airports">
              <span className="heading-base md:heading-2xl">{t('airportInfo')}</span>
            </TabListButton>
          </TabList>
          <TabPanels className={cx({ 'font-sans': isTailored })}>
            <TabPanel className="pt-8" id="nearby-locations">
              {nearbyLocations()}
            </TabPanel>
            <TabPanel className="pt-8" id="airports">
              {airportInfo()}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <div className="order-1 aspect-[3/2] w-full sm:h-[421px] md:order-2 md:w-1/2">
          <Map
            coordinate={coordinate}
            hotelName={hotelName}
            brandCode={brandCode}
            address={address}
            ctyhocn={ctyhocn}
            hideBrandLogo={hideBrandLogo}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationAndTransportation;
