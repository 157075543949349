import { useRect as useRectBase } from '@reach/rect';

export function useRect({
  ref,
  onResize,
}: {
  ref: Parameters<typeof useRectBase>[0];
  onResize?: (rect: DOMRect) => void;
}) {
  return useRectBase(ref, {
    onChange(rect) {
      onResize?.(rect as DOMRect);
    },
  });
}
